<template>
    <div class="page_container">
        <div class="page_head">
            <div class="page_head-title">ADMIN HOME</div>
        </div>
        <div class="page_content">
            <div class="page_content-stats">
                <div class="stat_item">
                    <div class="stat_item-title">Total Registered Teachers</div>
                    <div v-if="adminStats" class="stat_item-value">{{adminStats.userData.Teacher || 0}}</div>
                </div>
                <div class="stat_item">
                    <div class="stat_item-title">Total Registered Contributors</div>
                    <div v-if="adminStats" class="stat_item-value">{{adminStats.userData.Contributor || 0}}</div>
                </div>
                <div class="stat_item">
                    <div class="stat_item-title">Total Registered Coordinators</div>
                    <div v-if="adminStats" class="stat_item-value">{{adminStats.userData.Coordinator || 0 }}</div>
                </div>
            </div>
            <div class="page_content-lessons">
                <div class="lesson_count-text">
                    Total lessons uploaded
                    <div v-if="adminStats" class="lesson_count-box">{{adminStats.lessonsCount || 0}}</div>
                </div>
            </div>
            <div class="page_content-recent_lessons" v-if="adminStats">
                <div class="recent_title">Recently uploaded lessons</div>
              <router-link to="/admin_lessons">
                <div v-for="lesson of adminStats.recently" class="lesson_item">
                  <div class="lesson_item-data">
                    <div class="lesson_item-date">{{getDate(lesson.created_at)}}</div>
                    <div class="lesson_item-title">{{lesson.lesson_title}}</div>
                    <div class="lesson_item-name">{{lesson.user ? lesson.user.name : ''}}&nbsp; {{lesson.user ? lesson.user.name : ''}}</div>
                  </div>
                  <div class="">
                    <div v-for="team of lesson.team" class="lesson_item-team">{{team.team_name}}</div>
                  </div>
                  <div class="lesson_item-line"></div>
                </div>
              </router-link>

            </div>
<!--            <pre>{{adminData}}</pre>-->
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "AdminContent",
        data() {
            return {
                adminStats: null
            }
        },
        created() {
            this.getAdminData()
        },
        methods: {
            ...mapActions(['getAdminHomeData']),
            getAdminData() {
                this.getAdminHomeData()
                .then((res) => {
                    this.adminStats = res.data
                })
            },
            getDate(item) {
                let d = new Date(item).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
                return d
            },
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_container {
            width: 100%;
        }
        &_head {
            width: 100%;
            height: 73px;
            background: linear-gradient(180deg, #003761 0%, #00528C 79.64%);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px;
            position: sticky;
            top: 0;
            z-index: 3;
            &-title {
                color: #FFFFFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        &_content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 80px;
            &-stats {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                & .stat_item {
                    max-width: 300px;
                    width: 100%;
                    height: 130px;
                    border-radius: 12px;
                    border-left: 2px solid #00A99D;
                    background: #FAFAFB;
                    padding: 30px;
                    filter: drop-shadow(12px 0px 30px rgba(0, 169, 157, 0.25));
                    display: flex;
                    flex-direction: column;
                    &-title {
                        color: rgba(17, 51, 77, 0.60);
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        padding-bottom: 25px;

                    }
                    &-value {
                        color: #11334D;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-align: end;
                    }
                }
            }
            &-lessons {
                padding: 70px 0 30px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                & .lesson_count {
                    &-text {
                        position: relative;
                        width: 400px;
                        height: 60px;
                        background: #00A99D;
                        border-radius: 30px;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        color: #ffffff;
                        padding: 10px 30px;
                        display: flex;
                        align-items: center;
                    }
                    &-box {
                        position: absolute;
                        right: -10px;
                        top: -20px;
                        width: 100px;
                        height: 100px;
                        background: #003761 ;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #ffffff;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 700;
                        filter: drop-shadow(0px 4px 12px #004475);

                    }
                }
            }
            &-recent_lessons {
                max-width: 800px;
                width: 100%;
              & a {
                text-decoration: none;

              }
                & .recent_title {
                    color: #11334D;
                    font-size: 22.677px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding: 20px 0;
                }
                & .lesson_item {
                    position: relative;
                    margin: 15px 0;
                    max-width: 800px;
                    width: 100%;
                    height: 130px;
                    border-radius: 12px;
                    border: 1px solid #11334D;
                    padding: 10px  50px 10px 20px;
                    display: flex;
                    justify-content: space-between;
                    &-data {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                    }
                    &-date {
                        color: rgba(17, 51, 77, 0.60);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                    &-title {
                        color: #11334D;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        padding: 5px 0;
                    }
                    &-name {
                        color: rgba(17, 51, 77, 0.60);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                    &-team {
                        color: #11334D;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        padding: 2px 0;
                    }
                    &-line {
                        position: absolute;
                        right: 12px;
                        top: 0;
                        width: 10px;
                        height: 100%;
                        background: #00A99D;
                        border-left: 2px dotted #003761;
                    }
                }
            }

        }
    }
</style>
